import '../../css/store.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { screen, merchFilter } from '../types/enums';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import MerchCard from '../components/MerchCard';
import Profile from '../data/Profile';
import Utils from '../data/Utils';
import User from '../data/User';
import State from '../data/State';
import unsortMerch from '../data/merch';

export default observer(() => {
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState(merchFilter.ALL);
  const [searchString, setSearchString] = useState('');
  const merch = unsortMerch.sort((a, b) => a.price - b.price);

  const name = User.getName() === '' ? 'Привет' : User.getName();
  const data = 
    filter === merchFilter.EXCLUSIVE ? merch.filter(data => data.tag === filter) :
    filter === merchFilter.MAIN ? merch.filter(data => data.tag === filter) :
    filter === merchFilter.NEW_MERCH ? merch.filter(data => data.tag === filter) :
    filter === merchFilter.SEARCH ? merch.filter(data => data.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) :
    merch;
  
  const merchContent = data.map((data, index) => {
    const indent = index % 2 !== 0 ? <div className='store-product-indent'></div> : null;
    return (
      <div key={ data.id }>
        { indent }
        <MerchCard data={ data } callback={() => {
          if (User.isRegistered()) {
            State.setActiveMerch(data.id);
            State.setScreen(screen.MERCH);
          } else {
            setShowModal(true);
          }
        }} />
        {/* <div
          className='store-product-cart-button'
          onClick={() => {
            if (User.isRegistered()) {
              console.log('Добавить в корзину ' + data.id);
            } else {
              setShowModal(true);
            }
          }}
        >В корзину</div> */}
      </div>
    );
  });

  const modal = (
    <div className='store-modal-block'>
      <div className='store-modal'>
        <div className='store-modal-header'>Зарегистрируйся</div>
        <div className='store-modal-descr'>Для использования всех функциональных<br />возможностей бота необходимо<br />зарегистрироваться</div>
        <div className='store-modal-buttons-block'>
          <div className='store-modal-button' onClick={() => State.setScreen(screen.REG)}>Зарегистрироваться</div>
          <div onClick={() => setShowModal(false)}>Отменить</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='store-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='store-favorit-icon' onClick={() => {
        User.isRegistered() ? State.setScreen(screen.FAVORITES) : setShowModal(true);
      }}></div>
      <div className='store-cart-icon' onClick={() => {
        User.isRegistered() ? State.setScreen(screen.CART) : setShowModal(true);
      }}>
        { Profile.getCart().length > 0 && <div className='store-cart-mark'>{ Profile.getCart().length }</div> }
      </div>
      <div className='store-scores-block'>
        <div className='store-scores'>
          <div className='store-name'>{ name }, вам доступно</div>
          <div className='store-balance'>{ Utils.formatSum(User.getScores()) } Б</div>
        </div>
      </div>
      <div className='store-search-block'>
        <input
          className='store-search'
          type='text'
          placeholder='Поиск'
          onChange={e => {
            const value = e.target.value;
            setFilter(value === '' ? merchFilter.ALL : merchFilter.SEARCH);
            setSearchString(value);
          }}
          value={ searchString }
        />
      </div>
      <div className='store-switch-content'>
        <div className='store-switch-block'>
          <div
            className={'store-switch-button-' + (filter === merchFilter.ALL ? 'enable' : 'disable')}
            onClick={() => setFilter(merchFilter.ALL)}
          ><div>Все</div></div>
          <div
            className={'store-switch-button-' + (filter === merchFilter.NEW_MERCH ? 'enable' : 'disable')}
            onClick={() => setFilter(merchFilter.NEW_MERCH)}
          ><div>Новинка</div></div>
          <div
            className={'store-switch-button-' + (filter === merchFilter.EXCLUSIVE ? 'enable' : 'disable')}
            onClick={() => setFilter(merchFilter.EXCLUSIVE)}
          ><div>Экслюзив</div></div>
          <div
            className={'store-switch-button-' + (filter === merchFilter.MAIN ? 'enable' : 'disable')}
            onClick={() => setFilter(merchFilter.MAIN)}
          ><div>Основная</div></div>
        </div>
      </div>
      <div className='store-products-block'>{ merchContent }</div>
      {/* <div className='store-cart-button-block'>
        <div
          className='gold-button'
          onClick={() => {
            User.isRegistered() ? State.setScreen(screen.BAKSET) : setShowModal(true);
          }}
        >Перейти в корзину</div>
      </div> */}
      <div className='store-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
      { showModal && modal }
    </div>
  );
});