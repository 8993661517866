import { makeAutoObservable } from 'mobx';
import { Html5Qrcode } from 'html5-qrcode';
import { screen, productFilter } from '../types/enums';
import stories from './stories';

class State {
  constructor() {
    makeAutoObservable(this);
  }

  public readonly AGREEMENT: string = 'https://ss-bot.ru/agreement.pdf';
  public readonly PRIVACY: string = 'https://ss-bot.ru/privacy.pdf';
  private _screen: screen = screen.MAIN;
  private _screens: screen[] = [];
  private _error: number;
  private _scanWaiting: boolean;
  private _scanner: Html5Qrcode;
  private _activeStory: number;
  private _showStories: boolean;
  private _activeProduct: number;
  private _activeRecipe: number;
  private _activeMerch: number;
  private _productFilter: productFilter;
  private _gameBack: boolean = false;

  public getScreen(): screen {
    return this._screen;
  }

  public setScreen(screen: screen): void {
    const root = document.querySelector('#root');
    root.scrollTop = 0;
    this._screens.push(this._screen);
    this._screen = screen;
  }

  public toBackScreen(): void {
    this._screen = this._screens[this._screens.length - 1];
    this._screens.pop();
  }

  public getError(): number {
    return this._error;
  }

  public setError(error: number): void {
    this._error = error;
  }

  public setScanWaiting(value: boolean): void {
    this._scanWaiting = value;
  }

  public isScanWaiting(): boolean {
    return this._scanWaiting;
  }

  public setScanner(scanner: Html5Qrcode): void {
    this._scanner = scanner;
  }

  public getScanner(): Html5Qrcode {
    return this._scanner;
  }

  public setShowStories(value: boolean): void {
    this._showStories = value;
  }

  public isShowStories(): boolean {
    return this._showStories;
  }

  public getStories(): Istories[] {
    return stories;
  }

  public setActiveStory(id: number): void {
    this._activeStory = id;
  }

  public getActiveStory(): number {
    return this._activeStory;
  }

  public setActiveProduct(id: number): void {
    this._activeProduct = id;
  }

  public getActiveProduct(): number {
    return this._activeProduct;
  }

  public setActiveRecipe(id: number): void {
    this._activeRecipe = id;
  }

  public getActiveRecipe(): number {
    return this._activeRecipe;
  }

  public setActiveMerch(id: number): void {
    this._activeMerch = id
  }

  public getActiveMerch(): number {
    return this._activeMerch;
  }

  public setProductFilter(filter: productFilter): void {
    this._productFilter = filter;
  }

  public getProductFilter(): productFilter {
    return this._productFilter;
  }

  public isGameBack(): boolean {
    return this._gameBack;
  }

  public setGameBack(): void {
    this._gameBack = true;
  }
}

export default new State();