const DATE_REG_EXP = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;

class Utils {

  public static formatSum(sum: number): string {
    return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  public static isValidDate(dateString: string): boolean {
    const match: RegExpMatchArray | null = dateString.match(DATE_REG_EXP);
    if (!match) return false;
    const day: number = parseInt(match[1], 10);
    const month: number = parseInt(match[2], 10) - 1;
    const year: number = parseInt(dateString.substring(6, 10), 10);
    const date: Date = new Date(year, month, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    );
  }

  public static copy(text: string): void {
    navigator.clipboard.writeText(text).then(() => {}).catch(() => {
      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      textarea.innerHTML = text;
      textarea.focus();
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      document.documentElement.scrollTop = 0;
      window.scrollTo(0, 0);
    });
  }

  public static toGame(): void {
    const telegram = window.Telegram.WebApp;
    telegram.openTelegramLink('https://t.me/santo_stefano_bot/game');
    telegram.close();
  }
}

export default Utils;