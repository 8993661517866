import '../../css/cart.css';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import HeaderLogo from '../components/HeaderLogo';
import BackButton from '../components/BackButton';
import Tabbar from '../components/Tabbar';
import State from '../data/State';
import merch from '../data/merch';
import Profile from '../data/Profile';
import User from '../data/User';
import { screen } from '../types/enums';

export default observer(() => {
  const [showModal, setShowModal] = useState(false);
  const [choice, setChoice] = useState<number[]>([]);
  const [info, setInfo] = useState(false);

  useEffect(() => {
    const root = document.querySelector('#root');
    root.addEventListener('scroll', () => {
      const scrollTop = root.scrollTop;
      setInfo(scrollTop > 0 ? true : false);
    });
  }, []);

  const emptyCart = Profile.getCart().length === 0;

  const list: Imerch[] = [];
  Profile.getCart().map(data => {
    const item = merch.find(item => item.id === data.id);
    item && list.push(item);
  });
  list.sort((a, b) => a.id - b.id);
  
  const choiceList = () => {
    let chooseAll: boolean = true;
    Profile.getCart().map(data => {
      if (!choice.find(id => id === data.id)) {
        chooseAll = false;
        return;
      }
    });
    return chooseAll;
  }
  const chooseAll = choiceList();
  const chooseAllIcon = chooseAll ? 'enable' : 'disable';

  const cards = list.map(data => {
    const cart = Profile.getCart().find(item => item.id === data.id);
    const choose = Boolean(choice.find(id => id === data.id));
    const chooseIcon = choose ? 'enable' : 'disable';
    const favoriteIcon = Profile.getMerchFavorites().find(item => item === data.id) ? 'enable' : 'disable';

    return (
      <div key={ data.id } className='cart-card-block'>
        <div className='cart-card-left-block'>
          <div className={'cart-card-choose cart-choose-icon-' + chooseIcon} onClick={() => {
            if (choose) {
              setChoice(choice.filter(id => id !== data.id));
            } else {
              const ids = [data.id];
              choice.map(id => ids.push(id));
              setChoice(ids);
            }
          }}></div>
          <div style={{ backgroundImage: 'url(' + data.icon + ')' }} className='cart-card-merch-image'></div>
          <div className='cart-card-manage-block'>
            <div className={'cart-card-favorite ' + favoriteIcon} onClick={() => {
              favoriteIcon === 'enable' ? Profile.removeMerchFavorite(data.id) : Profile.addMerchFavorite(data.id);
            }}></div>
            <div className='cart-card-remove' onClick={() => Profile.removeCart(data.id)}></div>
          </div>
        </div>
        <div className='cart-card-right-block'>
          <div className='cart-card-name'>{ data.name.toUpperCase() }</div>
          <div className='cart-card-size'>
            {/* Размер { cart.size } */}
            &nbsp;&nbsp;&nbsp;
          </div>
          <div className='cart-card-price'>{ data.price } Б</div>
          { data.discount && <div className='cart-card-discount'>{ data.discount } Б</div> }
          <div className='cart-card-count-block'>
            <div className='cart-card-minus' onClick={() => {
              if (cart.count > 1) {
                Profile.setCart({
                  id: cart.id,
                  count: cart.count - 1,
                  size: cart.size
                });
              }
            }}></div>
            <div className='cart-card-count'>{ cart.count }</div>
            <div className='cart-card-plus' onClick={() => {
              Profile.setCart({
                id: cart.id,
                count: cart.count + 1,
                size: cart.size
              });
            }}></div>
          </div>
        </div>
      </div>
    );
  });

  const getCountMerch = () => {
    let count = 0;
    Profile.getCart().map(data => {
      if (choice.find(id => id === data.id)) {
        count += data.count;
      }
    });
    return count;
  }

  const getSum = () => {
    let sum = 0;
    Profile.getCart().map(data => {
      if (choice.find(id => id === data.id)) {
        const product = merch.find(item => item.id === data.id);

        if (product) {
          const price = product.discount || product.price;
          sum += (price * data.count);
        }
      }
    });
    return sum;
  }

  const getTotal = () => {
    let total = 0;
    Profile.getCart().map(data => {
      if (choice.find(id => id === data.id)) {
        const price = merch.find(item => item.id === data.id)?.price;
        
        if (price) {
          total += (price * data.count);
        }
      }
    });
    return total;
  }

  const count = getCountMerch();
  const sum = getSum();
  const total = getTotal();

  const registration = () => {
    const result: Icart[] = [];
    Profile.getCart().map(data => {
      if (choice.find(id => id === data.id)) {
        result.push(data);
      }
    });
    Profile.setOrder(result);
    total > User.getScores() ? setShowModal(true) : State.setScreen(screen.ORDER);
  }

  const modal = (
    <div className='cart-modal-block'>
      <div className='cart-modal'>
        <div className='cart-modal-header'>Недостаточно баллов</div>
        <div className='cart-modal-button' onClick={() => setShowModal(false)}>Закрыть</div>
      </div>
    </div>
  );

  return (
    <div className='cart-block'>
      <HeaderLogo />
      <BackButton callback={() => State.toBackScreen()} />
      <div className='cart-header-indent'></div>

      { !emptyCart && <div className='cart-manage-block'>
        <div className='cart-choose-all-block' onClick={() => {
          if (chooseAll) {
            setChoice([]);
          } else {
            const ids = [];
            Profile.getCart().map(data => ids.push(data.id));
            setChoice(ids);
          }
        }}>
          <div className={'cart-choose-icon-' + chooseAllIcon}></div>
          <div className='cart-choose-all-text'>Выбрать все</div>
        </div>
        <div className='cart-remove-all' onClick={() => {
          Profile.removeCartAll();
        }}>Удалить все</div>
      </div> }
      { cards }

      { !emptyCart && <div style={{ opacity: info || choice.length === 0 ? 1 : 0 }} className='cart-scroll-block'>
        <div className='cart-prmocode-block'>
          <input type='text' className='cart-prmocode-input' placeholder='Промокод' onChange={e => console.log(e.target.value)} />
          <div className='cart-prmocode-button' onClick={() => console.log('Применить')}>Применить</div>
        </div>
        <div className='cart-info-block'>
          <div className='cart-info-line'>
            <div>Количество</div>
            <div>{ count } шт</div>
          </div>
          <div className='cart-info-line'>
            <div>Сумма</div>
            <div>{ sum } Б</div>
          </div>
          <div className='cart-info-line'>
            <div>Скидка</div>
            <div style={{ color: '#BCBCC1' }}>{ sum - total } Б</div>
          </div>
          <div style={{ fontFamily: 'futura-pt-bold', fontSize: '23px' }} className='cart-info-line'>
            <div>Итого</div>
            <div style={{ color: '#BC9458' }}>{ total } Б</div>
          </div>
        </div>
        { choice.length > 0 && <div className='cart-registration-button' onClick={() => registration()}>Перейти к оформлению</div> }
        <div className='cart-tabbar-indent'></div>
      </div> }

      { !emptyCart && <div style={{ opacity: info || choice.length === 0 ? 0 : 1 }} className='cart-short-info-block'>
        <div className='cart-short-info'>
          <div className='cart-short-info-price'>{ total } Б</div>
          <div className='cart-short-info-button' onClick={() => registration()}>Перейти к оформлению</div>
        </div>
      </div> }

      { emptyCart && <div className='cart-empty-block'>
        <div className='cart-empty-text'>В корзине нет товаров</div>
        <div className='gold-button' onClick={() => State.setScreen(screen.STORE)}>Перейти в каталог</div>
      </div> }

      <Tabbar opacity={ 1 } />
      { showModal && modal }
    </div>
  );
});